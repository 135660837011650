import React, { useEffect } from 'react';
import { Modal, Form, Input, Select, Switch, TreeSelect } from 'antd';
import { useEmployee } from '../employee.hook';
import { EmployeeRequest } from '../../../request/employee.request';
import { UserInfo } from '../../../model/user-info.model';
import { UserRes } from '../../../response/user.response';
import { ItemProp } from '../../../model/item-prop.model';
import { RoleRes } from '../../../response/role.response';
import { OfficeRes } from '../../../response/office.response';
import { TeamRes } from '../../../response/team.response';
import { EmployeeRes } from '../../../response/employee.response';
import { UserRequest } from '../../../request/user.request';
import { Address } from '../../../model/address.model';
import { PermissionType } from '../../../enum/permission-type.enum';
import { Permission } from '../../../model/permission.model';
import { handleFilter } from '../../../view/input.helper';
import { useMain } from '../../main.hook';
import { formatName } from '../../../util/name-helper';
import { validateEmail, validateName, validatePhone } from '../../../validator/user-info.validator';
import FileUploadView from '../../../view/file-upload.view';
import { useNotification } from '../../../util/notification.hook';
const { SHOW_CHILD } = TreeSelect;

export const UpdateEmployeeComponent: React.FC<{ employee: EmployeeRes, open: boolean, onCancel: () => void }> = ({ employee, open, onCancel }) => {
    const [form] = Form.useForm();
    const { openNotification } = useNotification()

    const { loading, updateLoading, updateEmployee,
        fetchUserList, fetchOfficeList, fetchTeamList, fetchRoleList, fetchPermissionList,
        teamList, officeList, permissionList, roleList, userList, employeeList, fetchEmployeeTeamList, employeeTeamList } = useEmployee()

    const { userConfig } = useMain()

    const handleOk = () => {
        form.validateFields().then(values => {
            updateLoading(true);
            const employeeRequest = {
                userRequest: {
                    userInfo: {
                        name: values["name"],
                        phone: values["phone"],
                        email: values["email"],
                        ...(values["profilePic"] && { profilePic: values["profilePic"] })
                    } as UserInfo,
                    address: {
                        lineOne: values["lineOne"],
                        lineTwo: values["lineTwo"],
                        street: values["street"],
                        city: values["city"],
                        state: values["state"],
                        pinCode: values["pinCode"]
                    } as Address
                } as UserRequest,
                userId: values["userId"],
                roleId: values["roleId"],
                type: values["employeeType"],
                employeeRole: values["employeeRole"],
                officeId: values["officeId"],
                permissionList: values["permissionList"],
                teamIdList: values["teamIdList"],
                directCall: values["directCall"],
                ivrRegistered: values["ivrRegistered"],
                reportingTo: values["reportingTo"],
                hideFromWebsite: values["hideFromWebsite"],
            } as unknown as EmployeeRequest
            updateEmployee(employee.id, employeeRequest, onSuccess, onFailure)
        });
    };

    const onSuccess = () => {
        updateLoading(false);
        form.resetFields()
        onCancel();
        window.location.reload();
    }

    const onFailure = (err: any) => {
        if (err) {
            openNotification('error', err?.response?.data?.message ?? "Error in update", "")
        } else {
            openNotification('success', "Employee updated", "")
        }
        updateLoading(false);
    }

    useEffect(() => {
        // fetchEmployeeTeamList(employee.id)
        fetchUserList()
        fetchOfficeList()
        fetchTeamList()
        fetchRoleList()
        fetchPermissionList()
    }, [])

    return (
        <Modal
            open={open}
            title="Update Employee"
            onCancel={onCancel}
            confirmLoading={loading}
            onOk={handleOk}
            okText={"Submit"}
            cancelText={"Cancel"}
            style={{ top: 8, maxWidth: 768 }}
            width={'90vw'}
        >
            <Form form={form} layout="vertical">

                {/* <Form.Item
                    name="userId"
                    label="Select User"
                    rules={[{ required: false, message: 'Please select user' }]}
                    initialValue={employee.userId}
                >
                    <Select
                        showSearch={true}
                        value='userId'
                        options={userList.map((user: UserRes) => {
                            return {
                                label: `${formatName(user.userInfo.name)}`,
                                value: user.id
                            } as ItemProp
                        })}
                        allowClear
                        filterOption={handleFilter} />
                </Form.Item> */}

                {(userConfig.employeeRes.employeeRole == "Admin" ||
                    userConfig.employeeRes.employeeRole == "SuperAdmin" ||
                    userConfig.employeeRes.employeeRole == "Manager") &&

                    <Form.Item
                        name="phone"
                        label="Phone"
                        rules={[{ required: true, validator: validatePhone }]}
                        initialValue={employee.userInfo.phone}
                    >
                        <Input />
                    </Form.Item>
                }

                {(userConfig.employeeRes.employeeRole == "Admin" ||
                    userConfig.employeeRes.employeeRole == "SuperAdmin" ||
                    userConfig.employeeRes.employeeRole == "Manager") &&

                    <Form.Item
                        name="name"
                        label="Name"
                        rules={[{ required: true, validator: validateName }]}
                        initialValue={employee.userInfo.name}
                    >
                        <Input />
                    </Form.Item>
                }

                <Form.Item
                    name="email"
                    label="Email"
                    rules={[{ required: true, validator: validateEmail }]}
                    initialValue={employee.userInfo.email}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="roleId"
                    label="Select Role"
                    rules={[{ required: true, message: 'Please select role' }]}
                    initialValue={employee.roleId}
                >
                    <Select
                        showSearch={true}
                        value='roleId'
                        options={roleList.map((role: RoleRes) => {
                            return {
                                label: `${role.name}`,
                                value: role.id
                            } as ItemProp
                        })}
                        filterOption={handleFilter} />
                </Form.Item>

                <Form.Item
                    name="officeId"
                    label="Select Office"
                    rules={[{ required: true, message: 'Please select office' }]}
                    initialValue={employee.officeId}
                >
                    <Select
                        showSearch={true}
                        value='officeId'
                        options={officeList.map((office: OfficeRes) => {
                            return {
                                label: office.name,
                                value: office.id
                            } as ItemProp
                        })}
                        filterOption={handleFilter} />
                </Form.Item>

                <Form.Item
                    name="teamIdList"
                    label="Select teams"
                    rules={[{ required: false }]}
                    initialValue={employee.teamList.map((team: TeamRes) => { return team.id })}
                >
                    <Select
                        mode='multiple'
                        value='teamIdList'
                        options={teamList.map((team: TeamRes) => {
                            return {
                                label: team.name,
                                value: team.id
                            } as ItemProp
                        })}
                        maxTagCount='responsive'
                        filterOption={handleFilter} />
                </Form.Item>

                <Form.Item
                    name="permissionList"
                    label="Select Permissions"
                    rules={[{ required: true }]}
                    initialValue={employee.permissionList}
                >
                    <TreeSelect
                        treeData={permissionList.map((permission: Permission) => {
                            let children = [
                                {
                                    title: `${permission.label}-Read`,
                                    value: `${permission.value}-${PermissionType.ReadPermission}`,
                                    key: `${permission.value}-${PermissionType.ReadPermission}`,
                                },
                                {
                                    title: `${permission.label}-Write`,
                                    value: `${permission.value}-${PermissionType.WritePermission}`,
                                    key: `${permission.value}-${PermissionType.WritePermission}`,
                                },
                                {
                                    title: `${permission.label}-Update`,
                                    value: `${permission.value}-${PermissionType.UpdatePermission}`,
                                    key: `${permission.value}-${PermissionType.UpdatePermission}`,
                                },
                                {
                                    title: `${permission.label}-Delete`,
                                    value: `${permission.value}-${PermissionType.DeletePermission}`,
                                    key: `${permission.value}-${PermissionType.DeletePermission}`,
                                },
                                {
                                    title: `${permission.label}-Import`,
                                    value: `${permission.value}-${PermissionType.ImportPermission}`,
                                    key: `${permission.value}-${PermissionType.ImportPermission}`,
                                },
                                {
                                    title: `${permission.label}-Export`,
                                    value: `${permission.value}-${PermissionType.ExportPermission}`,
                                    key: `${permission.value}-${PermissionType.ExportPermission}`,
                                },
                            ]

                            if (permission.value == "report") {
                                children = [
                                    {
                                        title: `${permission.label}-Read`,
                                        value: `${permission.value}-${PermissionType.ReadPermission}`,
                                        key: `${permission.value}-${PermissionType.ReadPermission}`,
                                    },
                                    {
                                        title: `${permission.label}-Export`,
                                        value: `${permission.value}-${PermissionType.ExportPermission}`,
                                        key: `${permission.value}-${PermissionType.ExportPermission}`,
                                    }
                                ]
                            }
                            return {
                                title: permission.label,
                                value: `${permission.value}`,
                                key: `${permission.value}`,
                                children: children
                            }
                        })}
                        value='permissionList'
                        treeCheckable={true}
                        showCheckedStrategy={SHOW_CHILD}
                    />
                </Form.Item>

                {(userConfig.employeeRes.employeeRole == "Admin" ||
                    userConfig.employeeRes.employeeRole == "SuperAdmin") &&
                    <Form.Item
                        name="employeeRole"
                        label="Select Employee Access"
                        rules={[{ required: false }]}
                        initialValue={employee.employeeRole}
                    >
                        <Select
                            value='employeeRole'
                            options={[{
                                label: "Normal",
                                value: "Normal"
                            } as ItemProp,
                            {
                                label: "Leader",
                                value: "Leader"
                            } as ItemProp,
                            {
                                label: "Manager",
                                value: "Manager"
                            } as ItemProp,
                            {
                                label: "Admin",
                                value: "Admin"
                            } as ItemProp,
                            {
                                label: "SuperAdmin",
                                value: "SuperAdmin"
                            } as ItemProp
                            ]}
                            filterOption={handleFilter} />
                    </Form.Item>
                }

                <Form.Item
                    name="reportingTo"
                    label="Select reportee"
                    rules={[{ required: false }]}
                    initialValue={employee.reportingTo}
                >
                    <Select
                        showSearch={true}
                        value='reportingTo'
                        options={employeeList.map((employeeRes: EmployeeRes) => {
                            return {
                                label: employeeRes.userInfo.name,
                                value: employeeRes.id
                            } as ItemProp
                        })}
                        filterOption={handleFilter} />
                </Form.Item>

                <Form.Item
                    name="directCall"
                    label="DirectCall"
                    rules={[{ required: false }]}
                    initialValue={employee.directCall}
                >
                    <Switch defaultChecked={employee.directCall} />
                </Form.Item>

                <Form.Item
                    name="ivrRegistered"
                    label="Ivr Call allowed"
                    rules={[{ required: false }]}
                    initialValue={employee.employeeMeta?.ivrRegistered ?? false}
                >
                    <Switch defaultChecked={employee.employeeMeta?.ivrRegistered ?? false} />
                </Form.Item>

                <Form.Item
                    name="hideFromWebsite"
                    label="Hide From Website"
                    rules={[{ required: false }]}
                    initialValue={employee.hideFromWebsite}
                >
                    <Switch defaultChecked={employee.hideFromWebsite} />
                </Form.Item>

                <Form.Item
                    name="score"
                    label="Score"
                    rules={[{ required: true, message: 'Please enter score' }]}
                    initialValue={employee.score}
                >
                    <Input placeholder="Enter score" />
                </Form.Item>

                <Form.Item
                    name="profilePic"
                    label="Profile Pic"
                    rules={[{ required: true, message: 'Please add profile' }]}
                    initialValue={employee.userInfo.profilePic}
                >
                    <FileUploadView onChange={() => { }} />
                </Form.Item>
            </Form>
        </Modal>
    );
}